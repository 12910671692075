<template>
  <div class="tb-link">
    <div v-if="show" @click="jumpPage">
      <!-- 商品主图 -->
      <img class="main-pic" :src="sourceData.mainPic ? sourceData.mainPic : jd" alt="">

      <!-- 金额和标题 -->
      <div class="title-price">
        <!-- 商品价格 -->
        <div class="price" v-if="sourceData.actualPrice">
          <div class="price-item">
            到手价￥
            <span class="actualPrice">{{ sourceData.actualPrice.toString().split('.')[0] }}</span>
            <span class="actualPrice-small">.{{ sourceData.actualPrice.toString().split('.')[1] }}</span>
          </div>
        </div>
        <!-- 商品标题 -->
        <div class="title">
          {{ sourceData.title }}
        </div>
      </div>

      <!-- 商家店铺信息 -->
      <div class="shop-info">
        <!-- 名称和评分 -->
        <div class="shop-wrap">
          <div class="shop-name">
            {{ sourceData.shopName }}
          </div>
          <div class="shop-rate" v-if="sourceData.goodCommentsShare">
            <img src="@/assets/ic_comment@2x.png" alt="">
            商品好评率 {{ sourceData.goodCommentsShare }}%
          </div>
        </div>
        <div class="evaluate" v-if="sourceData.descScore">
          <div class="evaluate-item">商品评价 <span class="describe-item">{{sourceData.descScore}}</span></div>
          <div class="evaluate-item">售后服务 <span class="describe-item">{{sourceData.serviceScore}}</span></div>
          <div class="evaluate-item">物流履约 <span class="describe-item">{{sourceData.shipScore}}</span></div>
        </div>
      </div>

      <!-- 详情 -->
      <div class="goods-info">
        <img v-for="item in goodsImg" :key="item.img" class="goods-info-img" :src="item.img" alt="">
      </div>

      <!-- 京东按钮 -->
      <div class="tb-button">
        <img class="img-1" src="../../assets/jingdong@2x.png" alt=""> 打开京东 <img class="img-2" src="../../assets/taobao2.png"
          alt="">
      </div>
    </div>

    <loading :tips="tips" v-if="loadings"></loading>
  </div>
</template>

<script>
import aixos from '../../api/api'
import loading from '../../components/loading.vue'
export default {
  components: {
    loading,
  },
  data() {
    return {
      timer: null,
      sourceData: {},
      buyLink: '',
      callUpLink: '',
      mediaPlatform: '',
      productId: '',
      clickId: '',
      jumpSuccess: false,//跳转成功
      show: false,
      tips: '加载中',
      loadings: true,
      jd: require('../../assets/jd.png'),
      key: false,
    }
  },
  created() {
    if (this.$route.query) {
      this.mediaPlatform = this.$route.query.mediaPlatform;
      this.productId = this.$route.query.productId;
      this.clickId = this.$route.query.clickId
    }
    this.getCidLink();
    // 如果触发了这个事件就是跳转成功了
    document.addEventListener('visibilitychange', () => {
      this.jumpSuccess = true
    });
    setTimeout(() => {
      this.show = true
      this.loadings = false
    }, 1500)
  },

  methods: {
    numThousandthFormatAcion (num) {
      const val = (num.toString().indexOf('.') !== -1) ? parseFloat(parseFloat(num).toFixed(2)).toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      return val
    },
    numThousandthFormatZero (num) {
      let n = this.numThousandthFormatAcion(num)
      let arr = n.split('.')
      if (arr.length > 1) {
        if (arr[1].length === 1) {
          arr[1] += '0'
        }
        n = arr.join('.')
      } else {
        n += '.00'
      }
      return n
    },
    getCidLink() {
      aixos.post('api/page/jd/get', { 
        "mediaPlatform": this.mediaPlatform,
        "productId": this.productId, 
        "clickId": this.clickId, 
        "bizId": "" 
        // "mediaPlatform": 'XHS',
        // "productId": '10096200134317', 
        // "clickId": '111', 
        // "bizId": "" 
      }).then(res => {
        if (res.data.code == 200) {
          this.sourceData = res.data.data.productDetail || {};
          this.buyLink = res.data.data.buyLink;
          this.callUpLink = res.data.data.callUpLink;
          if (this.sourceData.imgs) {
            this.sourceData.mainPic = this.sourceData.imgs[0]
          } else {
            this.sourceData.mainPic = null
          }
          this.sourceData.actualPrice = this.sourceData.actualPrice ? 
            this.numThousandthFormatZero(this.sourceData.actualPrice) : null;
          window.location.href = this.callUpLink
          document.title = this.sourceData.title || null;
          this.goodsImg = this.sourceData.detailPics? this.sourceData.detailPics.splice(0, 6) : ''
        }
      })
    },
    jumpPage() {
      if (this.key) return
      this.key = true;
      aixos.post('api/page/jd/get', { 
        "mediaPlatform": this.mediaPlatform, 
        "productId": this.productId, 
        "clickId": this.clickId, 
        "bizId": "" 
      }).then(res => {
        if (res.data.code == 200) {
          window.location.href = res.data.data.callUpLink;
          this.buyLink = res.data.data.buyLink;
        }
        setTimeout(() => {
          if (!this.jumpSuccess) {
            window.location.href = this.buyLink;
            this.key = false;
          }
        }, 1500)
      })
    }
  }
}
</script>
<style scoped>
.tb-link{
  background-color: #f8f8f8;
  width: 100%;
  min-height: 100vh;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}
.img-2{
  width: 12px;
  height: 12px;
}
.tb-button{
  width: 112px;
  height: 32px;
  position: absolute;
  right: 0;
  font-size:12px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 20px;
  border-radius: 100px 0px 0px 100px;
  background: linear-gradient(315deg, #FD8800 0%, #FA5000 100%);
}
.main-pic{
  width: 100%;
}
.title-price{
  background: #FFFFFF;
  padding: 16px 24px 20px;
  margin-bottom: 12px;
}
.title{
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.title-item{
  display: inline-block;
  width: 48px;
  color: #FFFFFF;
  font-size: 14px;
  height: 20px;
  border-radius: 12px;
  text-align: center;
  line-height: 20px;
}
.tb{
  background: #FD3F31;
}
.price{
  margin-bottom:8px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.monthSales-item{
  color: #666666;
  font-size: 12px;
  height: 28px;
  display: flex;
  align-items: flex-end;
}
.monthSales{
  color: #FF4625;
}
.price-item{
  color: #F5072F;
  font-size: 12px;
  line-height: 20px;
}
.actualPrice{
  font-size: 28px;
  font-weight: 600;
}
.actualPrice-small{
  font-size: 18px;
  font-weight: 600;
}
.originalPrice{
  color: #999999;
  font-size: 12px;
}
.pay{
  color: #999999;
  display: inline-block;
  margin-left: 12px;
}
.logo-img{
  width: 72px;
  height: 72px;
}
.img-1{
  width: 28px;
  height: 28px;
}
.goods-info-img{
  width: 100%;
  margin-bottom: -4px;
}
.shop-info{
  background: #fff;
  margin-bottom: 12px;
}
.shop-info .shop-wrap {
  padding: 14px 24px 15px;
}
.shop-info .shop-wrap .shop-name{
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 20px;
  margin-bottom: 5px;
}
.shop-info .shop-wrap .shop-rate{
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #FA2D14;
  line-height: 14px;
  display: flex;
  align-items: center;
}
.shop-info .shop-wrap .shop-rate img{
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.evaluate{
  border-top: 2px solid #EEEEEE;
  height: 40px;
  display: flex;
  align-items: center;
  background: #FFF;
  justify-content: space-between;
}
.evaluate-item{
  flex: 1;
  height: 20px;
  line-height: 20px;
  border-right: 1px solid #eee;
  text-align: center;
}
.describe-item{
  font-size: 12px;
  color: #333;
}
</style>