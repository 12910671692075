<template>
  <div class="tb-link" >
        <div  v-if="show" @click="jumpPage">
            <!-- 商品主图 -->
            <img class="main-pic" :src="sourceData.mainPic ? sourceData.mainPic : tb" alt="">

                <!-- 金额和标题 -->
                <div class="title-price">
                    <!-- 商品标题 -->
                    <div class="title">
                        <span class="day-cat title-item" v-if="sourceData.shopType">天猫</span>
                        <span class="tb title-item" v-else>淘宝</span>
                        {{sourceData.title}}
                    </div>
                    <!-- 商品价格和销量 -->
                    <div class="price">
                        <div class="price-item">
                            活动价￥<span class="actualPrice">{{sourceData.actualPrice}}</span>起
                            <span class="pay">￥</span><del class="originalPrice">{{sourceData.originalPrice}}</del>
                        </div>
                        <div class="monthSales-item">
                            月销量<span class="monthSales">{{sourceData.monthSales}}+</span>
                        </div>
                    </div>
                </div>

                <!-- 商家店铺信息 -->
                <div class="business-info">
                    <!-- 商家logo -->
                <div class="logo" v-if="sourceData.shopLogo">
                    <img  class="logo-img" :src="sourceData.shopLogo" alt="">
                </div>
                
                <!-- 名称和评分 -->
                <div class="business">
                    <div class="business-title">
                        <div class="b-title">
                            {{sourceData.shopName}}
                        </div>
                        <div class="gold-wrapper">   
                            <div class="business-day-cat day-cat title-item" v-if="sourceData.shopType">天猫</div>
                            <div class="business-tb tb title-item" v-else>淘宝</div>
                            <div class="gold" v-if="sourceData.goldSellers">
                                <img class="gold-class" src="../../assets/gold.png" alt="">
                                金牌卖家
                            </div>
                        </div>
                    
                    
                    </div>

                    <div class="describe" v-if="isNull(sourceData.descScore)" >
                        <div>宝贝描述 <span class="describe-item">{{sourceData.descScore || '-'}}</span> </div>
                        <div>服务态度 <span class="describe-item">{{sourceData.serviceScore || '-'}}</span></div>
                        <div>物流服务 <span class="describe-item">{{sourceData.shipScore || '-'}}</span></div>
                    </div>
                </div>

                </div>

                <!--  -->
                <div class="goods-info">

                    <img v-for="item in goodsImg" :key="item.img" class="goods-info-img" :src="item.img" alt="">
                </div>

                <!-- 淘宝按钮 -->
                <div class="tb-button">
                  <img class="img-1" src="../../assets/taobao.png" alt="">  跳转至淘宝 <img class="img-2" src="../../assets/taobao2.png" alt="">
                </div>
        </div>

        <loading :tips="tips" v-if="loadings"></loading>
  </div>
</template>

<script>
import aixos from '../../api/api'
import loading from '../../components/loading.vue'
export default {
    components:{
        loading,
    },
    data () {
        return {
            timer:null,
            sourceData:{},
            buyLink:'',
            callUpLink:'',
            mediaPlatform:'',
            productId:'',
            clickId:'',
            jumpSuccess:false,//跳转成功
            show:false,
            tips:'加载中',
            loadings:true,
            tb:require('../../assets/taobao1.png'),
            key:false,
        }
    },
    created () {
        
        console.log('初始化',this.$route)
        if(this.$route.query){
            this.mediaPlatform = this.$route.query.mediaPlatform;
            this.productId = this.$route.query.productId;
            this.clickId = this.$route.query.clickId
        }
        this.getCidLink();

        // 如果触发了这个事件就是跳转成功了
        document.addEventListener('visibilitychange', () => {
			this.jumpSuccess = true
		});

        setTimeout( () => {
            this.show = true
            this.loadings = false
        },1500 )
    },

    methods:{
        getCidLink () {
            aixos.post('api/page/tb/get',{"mediaPlatform":this.mediaPlatform,"productId":this.productId,"clickId":this.clickId,"bizId":""}).then(res => {
                if(res.data.code == 200){
                    this.sourceData = res.data.data.productDetail;
                    this.buyLink = res.data.data.buyLink;
                    this.callUpLink = res.data.data.callUpLink;
                    window.location.href = this.callUpLink 
                    document.title = this.sourceData.title;
                    this.goodsImg = this.sourceData.detailPics.length > 6 ? this.sourceData.detailPics.splice(0,6) : ''
                }
            })
        },

        jumpPage() {
            if(this.key) return
            console.log('12312312')
            this.key = true;

            aixos.post('api/page/tb/get',{"mediaPlatform":this.mediaPlatform,"productId":this.productId,"clickId":this.clickId,"bizId":""}).then(res => {
                        if(res.data.code == 200){
                                window.location.href = res.data.data.callUpLink;
                                this.buyLink = res.data.data.buyLink;
                        }
                        setTimeout( () => {
                            if(!this.jumpSuccess){
                                window.location.href =  this.buyLink;
                                this.key = false;
                            }
                        },1500)
            })

        
        },
        isNull(val){
          if (!val || Number(val) === 0) {
            return false
          }
          return true
        },
    }
}
</script>

<style scoped src="./indx.css"></style>